.allDetailsAccordionFlushContainer .topRightCusBorderRadius {
  border-radius: 0px 10px 0px 0px !important;
}

.allDetailsAccordionFlushContainer .bottomRightCusBorderRadius {
  border-radius: 0px 0px 10px 0px !important;
}

.allDetailsAccordionFlushContainer .topLeftCusBorderRadius {
  border-radius: 10px 0px 0px 0px !important;
}

.allDetailsAccordionFlushContainer .bottomLeftCusBorderRadius {
  border-radius: 0px 0px 0px 10px !important;
}

.allDetailsAccordionFlushContainer .accordion-item {
  position: relative;
  border-width: 0px 0px 1px 0px;
}

.allDetailsAccordionFlushContainer .accordion-body {
  padding: 0px 1.25rem 20px 1.25rem;
}

.allDetailsAccordionFlushContainer .accordion-button {
  background-color: white;
  color: #0073E6;
  font-size: 15px;
  font-weight: 430;
  letter-spacing: -0.54px;
  padding: 1.4rem 1.2rem;
}



.allDetailsAccordionFlushContainer .accordion-button:focus {
  border-color: white;
  box-shadow: unset;
}

.allDetailsAccordionFlushContainer .accordion-button::after {
  background-size: 14px;
  height: 11px;
}

.allDetailsAccordionFlushContainer .accordion-button:not(.collapsed) {
  box-shadow: unset;
}

.allDetailsAccordionFlushContainer .accordion-button .tagSupportContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  font-size: 16px;
}

.allDetailsAccordionFlushContainer .timelineContainer {
  left: -35px;
  position: absolute;
  height: 102%;
  background: white;
  padding: 12px 12px;
}

.allDetailsAccordionFlushContainer .timelineContainerHanldeHeight {
  height: 100%;
}

.allDetailsAccordionFlushContainer .timelineContainer .line,
.allDetailsAccordionFlushContainer .timelineContainer .lineHelper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  /* background: #C5C5C5; */
  background: #0073E6;
  ;
  height: 100%;
  z-index: 0;
}



.allDetailsAccordionFlushContainer .timelineContainer .lastEleLineHelper {
  height: 10%;
  top: 20px;
}

.allDetailsAccordionFlushContainer .timelineContainer .lineHelper {
  width: 6px;
  background: white;
  top: 11px;
  height: 22.5px;
}




.allDetailsAccordionFlushContainer .timelineContainer .identificationIcon {
  width: 29px;
  position: relative;
  z-index: 15;
  margin-top: 10px;
}





/* START : superLock lock card style */

/* START : For accordance */
.allDetailsAccordionFlushContainer .accordion-item-theme-gray .accordion-button {
  color: #767676;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-gray .timelineContainer .line {
  background: #767676;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-brown .accordion-button {
  color: #845201;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-brown .timelineContainer .line {
  background: #845201;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-red .accordion-button {
  color: #EA2D2D;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-red .timelineContainer .line {
  background: #EA2D2D;
}

/* END : For accordance */



/* START : For series */
.allDetailsAccordionFlushContainer .accordion-item-theme-brown .seriesContainerC .seriesCard {
  background: #FDF2DC;
  cursor: not-allowed;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-gray .seriesContainerC .seriesCard {
  cursor: not-allowed;
}

/* END : For series */


/* END : For final exam */
.allDetailsAccordionFlushContainer .accordion-item-theme-brown .examContainerC .examCard,
.allDetailsAccordionFlushContainer .accordion-item-theme-brown .examContainer .examInnerContainer {
  background: #fae9c7;
  cursor: not-allowed;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-gray .examContainerC .examCard,
.allDetailsAccordionFlushContainer .accordion-item-theme-red .examContainerC .examCard {
  cursor: not-allowed;
}

/* END : For final exam */



/* START : For swipe button */
.allDetailsAccordionFlushContainer .swipeButtonDiv {
  width: 70%;
}

.allDetailsAccordionFlushContainer .swipeButtonDiv>span {
  margin-left: 30px;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-gray .examContainerC .examCard .swipeButtonDiv,
.allDetailsAccordionFlushContainer .accordion-item-theme-red .examContainerC .examCard .swipeButtonDiv,
.allDetailsAccordionFlushContainer .accordion-item-theme-brown .examContainerC .examCard .swipeButtonDiv {
  background: #DDD;
}

.allDetailsAccordionFlushContainer .examContainerC .examCard .swipeButton {
  position: sticky;
}

.allDetailsAccordionFlushContainer .accordion-item-theme-gray .examContainerC .examCard .swipeButton,
.allDetailsAccordionFlushContainer .accordion-item-theme-red .examContainerC .examCard .swipeButton,
.allDetailsAccordionFlushContainer .accordion-item-theme-brown .examContainerC .examCard .swipeButton {
  background: #BFBFBF;
  cursor: not-allowed;
}


.allDetailsAccordionFlushContainer .accordion-item-theme-gray .examContainerC .examCard .swipeButtonDiv .arrowLogo,
.allDetailsAccordionFlushContainer .accordion-item-theme-red .examContainerC .examCard .swipeButtonDiv .arrowLogo,
.allDetailsAccordionFlushContainer .accordion-item-theme-brown .examContainerC .examCard .swipeButtonDiv .arrowLogo {
  color: #858585;
}

/* END : For swipe button */


/* END : superLock lock card style */


@media (max-width: 1100px) {
  .allDetailsAccordionFlushContainer .swipeButtonDiv,
  .allDetailsAccordionFlushContainer .swipeButtonDiv .swipeButton {
    height: 30px;
  }
}

@media (max-width: 880px) {
  .allDetailsAccordionFlushContainer .swipeButtonDiv{
    width: 100%;
  }
}


@media (max-width: 768px) {
  .allDetailsAccordionFlushContainer .timelineContainer .identificationIcon {
    margin-top: 7px;
  }
}


@media (max-width: 568px) {
  .allDetailsAccordionFlushContainer .swipeButtonDiv {
    /* width: 70%; */
  }
}

@media (max-width: 568px) {

  .allDetailsAccordionFlushContainer .topRightCusBorderRadius,
  .allDetailsAccordionFlushContainer .bottomLeftCusBorderRadius,
  .allDetailsAccordionFlushContainer .topLeftCusBorderRadius,
  .allDetailsAccordionFlushContainer .bottomRightCusBorderRadius {
    border-radius: 0px 0px 0px 0px !important;
  }


  .allDetailsAccordionFlushContainer .timelineContainer .lastEleLineHelper {
    height: 65%;
  }

}