.profileTab .sTop {
    margin-bottom: 40px;
}

.profileTab .fHeadingContainer {
    padding: 0px 80px;
}

.profileTab .formContainer {
    padding: 32px 80px;
}

.profileTab .formContainer .displayPicContainer {
    justify-content: space-between;
    align-items: flex-start;
}

.profileTab .formContainer .displayPicContainer .d1 .heading {
    color: #252525;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.48px;
}

.profileTab .formContainer .displayPicContainer .d1 .subHeading {
    color: #A9A9A9;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.45px;
}

.profileTab .formContainer .displayPicContainer .d2 {
    cursor: pointer;
    width: 80px;
    height: 60px;
}

.profileTab .fHeadingContainer .fHeadingContainer_1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
}

.profileTab .fHeadingContainer .fHeadingContainer_1 .fHeading {
    color: #252525;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.54px;
    margin-bottom: 0px;
}

.profileTab .fHeadingContainer .fHeadingContainer_1 .tagsComponent {
    margin-bottom: 0px !important;
}

.profileTab .fHeadingContainer .fHeadingContainer_1 button {
    border-radius: 10px;
    background: #252525;
    color: white;
    border: 0px;
    font-size: 13px;
    padding: 3px 15px;
    font-weight: 400;
}

.profileTab .fHeadingContainer {
    font-size: 15px;
}

.profileTab .formContainer {
    background: white;
    border-radius: 10px;
}

.profileTab .formContainer .inputContainer_x {
    margin-bottom: 13px;
}

.profileTab .formContainer .inputContainer_x .inputLable,
.profileTab .formContainer .subForm .subFormSubHeading {
    color: #98979C;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.48px;
    margin-bottom: 1px;
    padding-bottom: 5px;
}

.profileTab .formContainer .inputContainer_x .asterisk,
.profileTab .formContainer .fileInputMainContainer .asterisk,
.profileTab .formContainer .inputContainerSelectTagTypeB .asterisk {
    color: #F00;
}


.profileTab .formContainer .inputContainer_x input[type="text"],
.profileTab .formContainer .inputContainer_x input[type="number"],
.profileTab .formContainer .inputContainer_x input[type="date"],
.profileTab .formContainer .inputContainer_x select {
    border-width: 0px 0px 1px 0px;
    border-color: #E0E0E0;
    width: 100%;
    letter-spacing: -0.54px;
    padding-bottom: 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/inputDateIcon.jpg) no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
}

.profileTab .formContainer .inputContainer_x select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>);
    background-repeat: no-repeat;
    background-position-x: 100%;
}

.profileTab .formContainer .inputContainer_x .inputLableCheckBox span {
    font-size: 16px;
    margin-left: 5px;
    font-weight: 500;
    letter-spacing: -0.48px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}


.profileTab .formContainer .inputContainer_x .extraParaText {
    margin-top: 6px;
    line-height: 17px;
}

.profileTab .formContainer .inputContainer_x .extraParaText span,
.profileTab .formContainer .inputContainer_x .extraParaText a {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.39px;
}

.profileTab .formContainer .inputContainer_x .extraParaText span {
    color: #A9A9A9;
}

.profileTab .formContainer .inputContainer_x .reSendBtn {
    color: #0d6efd;
    text-decoration: underline;
    border: unset;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.39px;
}


.profileTab .formContainer .inputContainerSupportVerify {
    position: relative;
}

.profileTab .formContainer .inputContainerSupportVerify .rightEle {
    position: absolute;
    right: 13px;
}

.profileTab .formContainer .inputContainerSupportVerify button {
    border-radius: 4px;
    border: 1px solid #C3C4C9;
    background: #FFF;
    color: #747474;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.36px;
}

.profileTab .formContainer .inputContainerSupportVerify .verifiedContainer {
    border-radius: 4px;
    border: 1px solid #139528;
    background: #EEFFE4;
    color: #139528;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.36px;
    padding: 2px 9px;
}

.profileTab .formContainer .inputContainerSupportVerify .verifiedContainer img {
    width: 13px;
    margin-right: 3px;
}

.profileTab .formContainer .subForm {
    border-top: 1px solid #E0E0E0;
    margin-top: 15px;
}

.profileTab .formContainer .subForm .subFormHeading {
    color: #98979C;
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    /* 100% */
    letter-spacing: -0.51px;
    margin-bottom: 6px;
    margin-top: 20px;
}


/* Start File input style */
.profileTab .formContainer .fileInputMainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.profileTab .formContainer .fileInputMainContainer .heading,
.profileTab .formContainer .inputContainerSelectTagTypeB .inputLable {
    color: #545454;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.45px;
    margin-bottom: 0px;
}

.profileTab .formContainer .fileInputMainContainer button {
    border-radius: 5px;
    border: 1px solid #D3D4D8;
    background: #FFF;
    color: #747474;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.48px;
    padding: 5px 15px;
}

.profileTab .formContainer .fileInputMainContainer button img {
    width: 14px;
    margin-left: 7px;
}

/* End File input style */


.profileTab .formContainer .inputContainerSelectTagTypeB {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 13px;
}

.profileTab .formContainer .inputContainerSelectTagTypeB .inputLable,
.profileTab .formContainer .inputContainerSelectTagTypeB .selectTag {
    width: fit-content;
}

.profileTab .formContainer .inputContainerSelectTagTypeB .selectTag {
    border-radius: 5px;
    border: 1px solid #D3D4D8;
    background: #FFF;
    color: #747474;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.39px;
    padding: 6px 10px;
}

.profileTab .formContainer .inputContainer_x input[type="text"]:focus,
.profileTab .formContainer .inputContainer_x input[type="number"]:focus {
    outline: none;
}

.profileTab .formContainer .tagsComponent span {
    font-size: 10px !important;
}

.profileTabLoaderMobile {
    display: none;
}

@media (max-width: 992px) {

    .profileTab .fHeadingContainer {
        padding: 0px 25px;
    }

    .profileTab .formContainer {
        padding: 15px 25px;
    }
}


@media (max-width: 768px) {
    .profileTab .fHeadingContainer {
        padding: 0px 15px;
    }

    .profileTab .formContainer {
        padding: 15px 15px;
    }
}

@media (max-width: 568px) {
    .profileTabLoaderDesktop {
        display: none;
    }

    .profileTabLoaderMobile {
        display: block;
    }

    .profileTab .formContainer .inputContainer_x .extraParaText {
        margin-top: -3px;
        margin-bottom: 7px;
    }    
}

@media (max-width: 568px) {
    .profileTab .formContainer .inputContainer_x .inputLable {
        margin-bottom: 0px;
    }

    .profileTab .formContainer .inputContainer_x input[type="text"],
    .profileTab .formContainer .inputContainer_x input[type="date"],
    .profileTab .formContainer .inputContainer_x input[type="number"] {
        margin-bottom: 12px;
    }
}

@media (max-width: 568px) {
    .profileTab .formContainer .tagsComponent span {
        font-size: 9px !important;
    }
}

@media (max-width: 370px) {
    .profileTab .formContainer .displayPicContainer .d2 {
        width: 74px;
    }
}