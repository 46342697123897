.nameAndPhoneNoOtpView .modal-header{
    border: 0px;
    padding-bottom: 0px;
}

.nameAndPhoneNoOtpView .modal-header .modal-title{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}

.nameAndPhoneNoOtpView .modal-header .modal-title img{
    width: 8px;
    margin-right: 9px;
    cursor: pointer;
}

.nameAndPhoneNoOtpView .modal-header .closeBtn{
    background: transparent;
    border: 0px;
    color: #D2D2D2;
    font-size: 20px;
    font-weight: 600;
}

.nameAndPhoneNoOtpView .modal-body .verifyNumText{
    color: #878787;
    font-weight: 400;
    font-size: 15px;
}

.nameAndPhoneNoOtpView .modal-body{
    padding-top: 5px;
}




/* START : Style for initStageContainer */
.nameAndPhoneNoOtpView .modal-body .initContainer .registrationMainContainer{
    border: 0px;
}

.nameAndPhoneNoOtpView .modal-body .initContainer .registrationMainContainer .heading{
    display: none;
}

.nameAndPhoneNoOtpView .modal-body .initContainer .registrationMainContainer .tncContainer{
    margin-bottom: 15px;
}

.nameAndPhoneNoOtpView .modal-body .initContainer .countryCodeFlagPhoneNoViewMainContainer .css-1xc3v61-indicatorContainer{
    padding: 1px;
}

.nameAndPhoneNoOtpView .modal-body .initContainer .countryCodeFlagPhoneNoViewMainContainer .css-tj5bde-Svg{
    width: 18px;
}

/* END : Style for initStageContainer */



/* START : Style for otpStageContainer */
.nameAndPhoneNoOtpView .modal-body .otpStageContainer{
    margin-top: 20px;
    margin-bottom: 10px;
}

.nameAndPhoneNoOtpView .modal-body .otpStageContainer .otpInputContainer .otp{
    width: 40px;
    height: 52px;
    margin-right: 16px;
    font-size: 23px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #E9E9E9;
}


.nameAndPhoneNoOtpView .modal-body .otpStageContainer .resendOtpBtn{
    background: transparent;
    border: 0px;
    text-decoration: underline;
    padding: 0px;
    color: #1A73E8;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 30px;
}

.nameAndPhoneNoOtpView .modal-body .otpStageContainer .tnc{
    display: none;
}

.nameAndPhoneNoOtpView .modal-body .otpStageContainer .resendText{
    color: #8C8C8C;
    font-size: 15px;
    font-weight: 400;
}

.nameAndPhoneNoOtpView .modal-body .otpStageContainer .resendText{
    text-decoration: unset !important;
}



.nameAndPhoneNoOtpView .otpStageContainer .otpSubmitBtnContainer .otpSubmitBtn {
    border-radius: 5px;
    width: 100%;
    background: #1573FE;
    border: 0px;
    color: #FFF;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.15px;
    padding: 6px 10px;
}


.nameAndPhoneNoOtpView .otpStageContainer .otpError{
    margin-bottom: 5px;
    color: red;
    font-size: 13px;
}
/* END : Style for otpStageContainer */


.nameAndPhoneNoOtpView .modal-footer{
    border: 0px;
}


@media (max-width: 568px) {
    .nameAndPhoneNoOtpView .modal-dialog {
        margin: 1rem 0rem 1px 0rem;
        display: flex !important;
        justify-content: flex-end;
        flex-direction: column;
        height: 100%;
    }

    .nameAndPhoneNoOtpView .modal-content {
        border-radius: 10px 10px 0px 0px;
    }
}