/* 
REMOVE IT
.writeToUsView .callView{
    display: block !important;
}
.writeToUsView .callView .callBtn img{
    margin-right: 15px;
}
*/

.writeToUsView .writeToUseSubmittedContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.writeToUsView .writeToUseSubmittedContainer img{
    width: 73px;
    margin-bottom: 8px;
}
.writeToUsView .writeToUseSubmittedContainer p{
    font-size: 17px;
    font-weight: 500;
    color:#1573FE;
    max-width: 240px;
} 


.writeToUsView .writeToUseContainer .registrationMainContainer{
    border: 0px;
}
.writeToUsView .writeToUseContainer .registrationMainContainer .tncContainer{
    display: flex;
    align-items: flex-start;   
}
.writeToUsView .writeToUseContainer .registrationMainContainer .tncContainer .form-check-input[type=checkbox]{
    font-size: 13px;
}
.writeToUsView .writeToUseContainer .registrationMainContainer .tncContainer label{
    width: 96%;
    margin-left: 10px;
}
.writeToUsView .writeToUseContainer .registrationMainContainer .tncContainer .imIntrestedIconSendBtn{
    width: 12px;
}

.writeToUsView .css-1fdsijx-ValueContainer{
    padding: 2px 0px;
}


.writeToUsView .writeToUseContainer .writeToUseError{
    color: #982424;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
}
.writeToUsView .writeToUseContainer textarea{
    font-size: 14px;
    font-weight: 500;
}
.writeToUsView .writeToUseContainer{
    position: relative;
    margin-bottom: 15px;
}
.writeToUsView .writeToUseContainer .sendBtn{
    cursor: pointer;
    width: 12px;
    position: absolute;
    top: 75.5%;
    right: 2.5%;    
}


/* test */
.bottomStickyContainer{
    display: none;
}



.writeToUsView .callView{
    margin-top: -15px;
    display: block !important;
}