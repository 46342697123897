.blogView .blogViewInnerContainer {
	margin-bottom: 50px;
}

.blogView .title,
.blogView h2 {
	font-size: 18px !important;
	font-weight: 600;
}

.blogView .description,
.blogView p {
	font-size: 16px !important;
	font-weight: 400;
	font-family: "Jomolhari", serif;
}

.blogView .thumbnail {
	width: 60%;
}

@media (min-width: 1000px) {
	.blogView .thumbnail {
		width: 30%;
	}
}
